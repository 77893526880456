import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const ServicePage= () => {
return (
<Box component="main" sx={{  backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/76459461-double-exposure-of-professional-businessman-connected-devices-with-world-digital-technology-internet.jpeg')",p: 3 }} position='relative' margin='auto' width={900}>

<Stack container spacing={1}>
<Box sx={{  p: 3 }} position='relative' margin='auto' >
<Card sx={{ maxWidth: 800}} >
      <CardMedia
        sx={{ height: 400}}
        image="https://primecomputers.s3.us-west-1.amazonaws.com/9aadae_913f6ad74e34357f3c86519fe28d0823.webp"
        title=" ​Laptop and Desktop Repair"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{  backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/professional-photo-background.jpeg')",p: 3 }}>
        ​Laptop and Desktop Repair
        </Typography>
        <Typography variant="body2" color="text.secondary">
        When your laptop or desktop is not working at its best it can be a real problem. Depend on us to get it working again fast so that you can get back to work. From hard drive and memory upgrades to logic board repair our well trained technicians are ready to help. We will do our best to get your machine back to you quickly and affordably while paying close attention to saving your precious information. 
        Unlike other shops we take extra steps to ensure the safety of your data through the repair process.
         After all your computer is replaceable but your memories and information are not.
        Pc Desktops, and PC Laptops welcome!
        </Typography>
      </CardContent>
     
    </Card>
  
  
<Card sx={{ maxWidth: 800}}>
      <CardMedia
        sx={{ height: 400}}
        image="https://primecomputers.s3.us-west-1.amazonaws.com/9aadae_a72f81e6b57cc9c45b68e1c80ddbd723.webp"
        title=" Liquid Damage Repair"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{  backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/professional-photo-background.jpeg')",p: 3 }}>
        Liquid Damage Repair
        </Typography>
        <Typography variant="body2" color="text.secondary">
        The worst of the worst. We all know that sinking feeling when you spill something into your laptop or your phone drops into water.
         Luckily the technicians at Prime Computers are experts at getting your device to function again.
          We have special training and equipment which helps us dive deep into the components of your machine and salvage any parts that we can while giving you a great deal on the parts that we can't.
           Your device may not have to be replaced after all.
            If for whatever reason if your device is not repairable we will do our best to make sure your data doesn't end up down the drain. 
        </Typography>
      </CardContent>
     
    </Card>
  
<Card sx={{ maxWidth: 800}}>
      <CardMedia
        sx={{ height: 400}}
        image="https://primecomputers.s3.us-west-1.amazonaws.com/9aadae_70243cd5c9044a758ce985abe5dae7c9.webp"
        title="Data Recovery "
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{  backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/professional-photo-background.jpeg')",p: 3 }}>
        Data Recovery 
        </Typography>
        <Typography variant="body2" color="text.secondary">
        A computer is just a machine that can be replaced but your precious data, photos, music, videos, and documents are what are truly important.
         Our technicians will come to the rescue when your digital life is in jeopardy. 
         We have specialized tools and techniquies that help up get your data back safely and securely.
          Whether your computer will not turn on, your hard drive is crashed, your operating system is corrupted, or your external hard drive will not show up we will do our best to recover as much as we can, as fast as we can.
           We also offer free data recovery consultations and diagnostics so that you can rest at ease. 
        </Typography>
      </CardContent>
     
    </Card>

    <Card sx={{ maxWidth: 800}}>
      <CardMedia
        sx={{ height: 400}}
        image="https://primecomputers.s3.us-west-1.amazonaws.com/Windows_logo_Cyan_rgb_D.webp
        "
        title=" Windows Repair/Services"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div"sx={{  backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/professional-photo-background.jpeg')",p: 3 }}>
        Windows Repair/Services
        </Typography>
        <Typography variant="body2" color="text.secondary">
        We currently deal with windows PC repair/ windows hardware repair.
         We don't deal in software or IT operation system.
        </Typography>
      </CardContent>
     
    </Card>
    </Box>
</Stack>


   
</Box>

)}
export default ServicePage;