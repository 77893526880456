import React from 'react';
import "./footer.css";
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faLinkedin, faDribbble } from '@fortawesome/free-brands-svg-icons'
function Footer() {
    return (
        <div>
                 <footer>
                    <footer className="footer">
                        <div className="container">
                            <div className="row">
                                

                                <div className="col-lg-3 mb-4 mb-lg-0">
                                    <h4 className="mb-4">About us</h4>
                                    <p>
                                    We are pioneer in the IT hardware Sales and Service industry, 
                                    we are distributor and retailers of major vendors and corporations for last 15 years and have more than 2 decades
                                      of industry footprint.
                                    </p>
                                    
                                </div>
                                

                                <div className="col-lg-2 mb-4 mb-lg-0">
                                    <div className="footer-menu">
                                        <ul>
                                            <li><div  to='#'><b><h4>Quick Links</h4></b></div ></li>
                                            <li><Link to="/Home">Home</Link ></li>
                                            
                                            <li><Link to="/Services">Services</Link ></li>
                                            <li><Link to="/Sales">Sales</Link ></li>
                                        </ul>
                                    </div>
                                </div>
                                
                    
                                <div className="col-lg-3 mb-4 mb-lg-0">
                                <div className="footer-menu">
                                        <ul>
                                            <li><div  to='#'><b><h4>Brands we offer</h4></b></div ></li>
            
                                            <li><div >Lenovo</div ></li>
                    
                                            <li><div  ></div >Acer</li>
                                            <li><div  ></div >Asus</li>
                                            <li><div  ></div >Dell</li>
                                            <li><div  ></div >Hp</li>
                                            <li><div  ></div >Apple</li>
                                            <li><div  ></div >Alienware</li>
                                            <li><div  ></div >Samsung</li>
                                            
                                           
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-6 mb-lg-0">
                                <div className="footer-menu">
                                        <ul>
                                            <li><div  to='#'><b><h4>Services we offer</h4></b></div ></li>
            
                                            <li><div >Hardware repair</div ></li>
                    
                                            <li><div  ></div >Screen Repair/Replacement</li>
                                            <li><div  ></div >Liquid Damage Repair</li>
                                            <li><div  ></div >Data Recovery</li>
                                            <li><div  ></div >Motherboard Repair</li>
                                            <li><div  ></div >Data Wiping</li>
                                            <li><div  ></div >Antivirus </li>
                                            <li><div  ></div >OS Installation/Format</li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                    
                    <section className="copyright py-4 text-white">
                        <div className="container">
                            <small className="pre-wrap">
                                <p>
                                    ©2019, Prime Computers. All Rights Reserved.
                                    
                        </p>
                            </small>
                        </div>
                    </section>
                </footer>
                </div>

        );
}

export default Footer;