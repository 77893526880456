import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SwipeableTextMobileStepper from '../Carousel/carousel'
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import { maxWidth } from '@mui/system';

const Aboutpage= () => {
return (
<Box component="main" sx={{  backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/professional-photo-background.jpeg')",p: 3  }} position='relative' margin="auto" marginTop={-7}   >
  <SwipeableTextMobileStepper/>
  
<Box sx={{  p: 3 }} position='relative' margin="auto">
  <Grid container spacing={3}>
    <Grid item xs={6} >
    <Typography  sx={{ fontSize: 20  }} color="Black" variant="h6" >
  
    <u><h1>What We Offer</h1></u>
  <br></br>
  <p>
  Your one stop shop for all of your tech and computer needs. 
    We specialize in Pc laptops and Desktops products as well as accessories, and peripherals. 
    As technical creative professionals we understand the needs of film editors, graphic designers, photographers, and general users. We will be able to he﻿lp you fill your needs without breaking the bank.​
​
We take a personal interest in our clients needs. We are always here to help you even after you purchase your computer
. Years down the road if you feel like you need more speed or storage space we will be available to fill your needs.
 We can work with you to as your needs change and as you become a more efficient computer user. 
As technology changes we will make sure your always up to date so you can keep up with a faster moving world. 

  </p>
    


​
</Typography>
    </Grid>
 
  
    <Grid item xs={6} >
    <Typography sx={{ fontSize: 20  }} color="Black" variant="h6">
  
    <u><h1>Why us</h1></u>
  <br></br>
  <p>
  If you are in the market for a new computer you probably have an old one that you won't need anymore. Not only can we help recycle your old machine we can also assist you with data transfer to your new machine. We can also make sure your data is securely removed from your old machine after you are satisfied that everything has transferred correctly. We may even be able to give your some credit towards your new computer.

﻿

Your old computer may not be worth much to you but through our charity programs it could be worth a lot to someone less fortunate. Giving the gift of information to someone is one of the best gifts you can give. It allows someone to access knowledge that wasn't available to them before and can help them advance their future and in turn the future of the world. 

﻿

If your computer is no longer functional it can be used for parts to lengthen the life of another computer. If we can not find a another computer to put the parts in we will make sure it is recycled responsibly so that it doesn't do damage to our environment. 

​

​

Please stop by at our showroom for an instant evaluation of your computers or selling/trading-up for a new or refurbished computers 

  </p>
    


​
</Typography>
    </Grid>
  </Grid>

 
</Box>
</Box>

)}
export default Aboutpage;